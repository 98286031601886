import React, {useRef, useMemo, useCallback} from "react";
import styles from "./Map.module.css";
import solarIcon from "../../../../static/img/solar-panel_BW_32x32.png"

import {
    GoogleMap,
    MarkerF,
} from "@react-google-maps/api"
//import Places from "./places"
//import Distance from "./distance"

const Map = (props) => {
    const mapRef = useRef()
    const center = useMemo(()=>(props.home),[props.home])
    const options = useMemo(()=>({
        disableDefaultUI: true,
        clickableIcons:false,
        zoomControl:true,
        controlSize: 20,
        zoomControlOptions:{
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        }
    }),[])
    const onLoad = useCallback((map)=>(mapRef.current = map), [])
    

  return (
      <div id={styles.container}>
        <GoogleMap 
            zoom={10} 
            center={center} 
            mapContainerClassName={styles.map_container}
            options={options}
            onLoad={onLoad}
            onClick={(e)=>{props.handleClick(e.latLng.toJSON())}}
            >
            <MarkerF position={props.home} icon={solarIcon}/>
        </GoogleMap>
      </div>
  
  );
};
export default Map;
