import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    clearSky:[],
    solarForecast: [],
    isLoading: false
}

export const getClearSky = createAsyncThunk(
    'solardata/getClearSky',
    async (installation, thunkAPI) => {
        
      try {
        const resp = await axios({
            url:'/api/clearsky',
            method: 'POST',
            headers:{
                'accept':'application/json',
                'Content-Type':'application/json'
            },
            data:installation
        });
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

export const getSolarForecast = createAsyncThunk(
    'solardata/getSolarForecast',
    async (installation, thunkAPI) => {
        
      try {
        const resp = await axios({
            url:'/api/forecast',
            method: 'POST',
            headers:{
                'accept':'application/json',
                'Content-Type':'application/json'
            },
            data:installation
        });
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

export const solarDataSlice = createSlice({
    name: 'solardata',
    initialState:initialState,
    reducers:{},
    extraReducers: (builder) =>
    {
        builder
        .addCase(getClearSky.pending, (state,action) => {
            state.isLoading=true}
            )
        .addCase(getClearSky.fulfilled, (state,action) => {
            state.isLoading = false
            state.clearSky = action.payload
        })
        .addCase(getClearSky.rejected, (state) => {state.isLoading=false})
        .addCase(getSolarForecast.pending, (state,action) => {
            state.isLoading=true}
            )
        .addCase(getSolarForecast.fulfilled, (state,action) => {
            state.isLoading = false
            state.solarForecast = action.payload
        })
        .addCase(getSolarForecast.rejected, (state) => {state.isLoading=false})
    }

})


export default solarDataSlice.reducer