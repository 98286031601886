import React from "react";
import styles from "./SignInPage.module.css";
import Login from "./Login";
import LineSeparator from "./LineSeparator";

const provider = {
  microsoft: {
    name: "Microsoft",
    route: "aad",
    logo: "Microsoft-32x32.png",
    color: "#C4C4C4",
    border: "#C4C4C4",
    background: "#FFF",
  },
  github: {
    name: "GitHub",
    route: "github",
    logo: "GitHub-32x32.png",
    color: "#FFF",
    border: "#000",
    background: "#000",
  },
  twitter: {
    name: "twitter",
    route:"twitter",
    logo: "twitter-32x32.png",
    color: "#39AAE7",
    border: "#39AAE7",
    background: "#FFF",
  },
};

const SignInPage = (props) => {
  const myGrey = "#c4c4c4";
  return (
    <div className={styles.container}>
      <div className={styles.signInPage}>
        <h3> Sign In Options</h3>
        <div className={styles.providerContainer}>
          <Login
            provider={provider.microsoft}
            handleClick={props.handleClick}
          />
          <LineSeparator text="Or" color={myGrey} />
          <Login provider={provider.github} handleClick={props.handleClick} />
          <LineSeparator text="Or" color={myGrey} />
          <Login provider={provider.twitter} handleClick={props.handleClick} />
        </div>
      </div>
    </div>
  );
};
export default SignInPage;
