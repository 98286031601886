import styles from "./Footer.module.css";
import {ReactComponent as GitHubLogo} from "../../../static/img/github.svg";
import {ReactComponent as LinkedInLogo} from "../../../static/img/linkedin_org.svg";
import {ReactComponent as OpensourceLogo} from "../../../static/img/opensource.svg";

const Footer = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.author}>
        <p>&copy;2023 Peter Tribout | All Rights Reserved</p>
      </div>
      <div className={styles.logos}>
        <div className={`${styles.githublogo} ${styles.logo}`}>
          <a href="https://github.com/tribp">
            <GitHubLogo/>
          </a>
        </div>
        <div className={`${styles.linkedinlogo} ${styles.logo}`}>
          <a href="https://be.linkedin.com/in/peter-tribout-b1b564186">
            <LinkedInLogo/>
          </a>
        </div>
        <div className={styles.opensourcelogo}>
          <OpensourceLogo/>
          <p>Open source</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
