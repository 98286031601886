import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import MainHeader from "./components/MainHeader";
import Footer from "./components/UI/Footer/Footer";
import Welcome from "./pages/Welcome";
import Installation from "./pages/Installation";
import User from "./pages/User";

import { checkUser, createUser, updateUser } from "./services/users/users";

import { useSelector, useDispatch } from "react-redux";

import "./App.css";
import { setClientPrincipal, initialState } from "./features/user/userSlice";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store);

  useEffect(() => {
    (async () => {
      // get basic user info from delegated OAuth provider
      let userInfo = await getUserInfo();
      let updatedUser = await createOrUpdateUser(userInfo);
      dispatch(setClientPrincipal(updatedUser));
    })();
  }, [dispatch]);

  async function getUserInfo() {
    try {
      const resp = await fetch("/.auth/me");
      const payload = await resp.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    } catch (error) {
      console.error("no profile could be found");
      return undefined;
    }
  }
  async function createOrUpdateUser(userInfo) {
    //create or update by merging Oauth, existing db user conform all redux state keys
    let now = new Date().toISOString();
    let thisUser = initialState;
    // if successful
    if (userInfo && userInfo.userId) {
      // check if exists already in db
      let foundUser = await checkUser(userInfo.userId);
      if (foundUser) {
        //take initalStae to have all keys, then overwrite from db ,finaly with Oauth keys
        thisUser = {
          ...initialState,
          ...foundUser,
          ...userInfo,
        };
        thisUser.updatedAt = now;
        thisUser.log.push(now);
        // we keep max 10 login history
        if (thisUser.log.length > 20) {
          thisUser.log.shift();
        }
        await updateUser(thisUser);
      } else {
        thisUser = {
          ...initialState,
          ...userInfo,
        };
        thisUser.createdAt = now;
        thisUser.updatedAt = now;
        thisUser.log = [];
        thisUser.log.push(now);
        delete thisUser["_id"];
        const createdUser = await createUser(thisUser);
        thisUser._id = createdUser._id;
      }
    }
    return thisUser;
  }

  function handleLogin(provider) {
    console.log("Login:", provider);
  }

  return (
    <div className="app">
      <header>
        <MainHeader user={user} />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Navigate replace to="/welcome" />} />
          <Route
            path="/welcome"
            element={<Welcome user={user} handleClick={handleLogin} />}
          />
          <Route path="/installation" element={<Installation user={user} />} />
          <Route path="/user" element={<User user={user} />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
