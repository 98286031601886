import React from "react";
import styles from "./Entry.module.css";
import ReactTooltip from 'react-tooltip'
import {FaInfoCircle} from 'react-icons/fa'


const Entry = (props) => {
  return (
    <div className={styles.container}>
      <h6>{props.title}</h6>
      <div className={styles.entry_box}>
        <div className={styles.entry}>
          <input name={props.name} className={styles.value} value={props.value} onChange={(e)=>props.onChange(e)}/>
          <div className={styles.dimension}>{props.dim}</div>
        </div>
        <div className={styles.info} data-tip data-for={props.title}><FaInfoCircle /></div>
        <ReactTooltip id={props.title}>{props.info}</ReactTooltip>
      </div>
    </div>
  );
};
export default Entry;
