export const Daily_kWh = [
  {
    Day: 1,
    kWh: 3.4675,
    kWh_predict: 2.60875,
    clear_sky: 21.16975,
  },
  {
    Day: 2,
    kWh: 1.2055,
    kWh_predict: 0.52175,
    clear_sky: 21.28,
  },
  {
    Day: 3,
    kWh: 0.908,
    kWh_predict: 1.18675,
    clear_sky: 21.40275,
  },
  {
    Day: 4,
    kWh: 13.57175,
    kWh_predict: 8.6835,
    clear_sky: 21.53875,
  },
  {
    Day: 5,
    kWh: 0.8985,
    kWh_predict: 4.34225,
    clear_sky: 21.68775,
  },
  {
    Day: 6,
    kWh: 24.216,
    kWh_predict: 15.437,
    clear_sky: 21.84725,
  },
  {
    Day: 7,
    kWh: 7.59925,
    kWh_predict: 6.00775,
    clear_sky: 22.01925,
  },
  {
    Day: 8,
    kWh: 1.86275,
    kWh_predict: 1.25375,
    clear_sky: 22.2045,
  },
  {
    Day: 9,
    kWh: 1.068,
    kWh_predict: 1.6925,
    clear_sky: 22.3995,
  },
  {
    Day: 10,
    kWh: 5.8705,
    kWh_predict: 7.487,
    clear_sky: 22.6075,
  },
  {
    Day: 11,
    kWh: 6.79475,
    kWh_predict: 11.2695,
    clear_sky: 22.82725,
  },
  {
    Day: 12,
    kWh: 4.157,
    kWh_predict: 5.286,
    clear_sky: 23.058,
  },
  {
    Day: 13,
    kWh: 5.34925,
    kWh_predict: 3.71725,
    clear_sky: 23.29875,
  },
  {
    Day: 14,
    kWh: 1.43275,
    kWh_predict: 1.07125,
    clear_sky: 23.55025,
  },
  {
    Day: 15,
    kWh: 1.1875,
    kWh_predict: 5.41925,
    clear_sky: 23.813,
  },
  {
    Day: 16,
    kWh: 9.91625,
    kWh_predict: 7.80575,
    clear_sky: 24.09625,
  },
  {
    Day: 17,
    kWh: 7.5585,
    kWh_predict: 7.42825,
    clear_sky: 24.40225,
  },
  {
    Day: 18,
    kWh: 11.156,
    kWh_predict: 16.775,
    clear_sky: 24.71775,
  },
  {
    Day: 19,
    kWh: 20.5895,
    kWh_predict: 17.70275,
    clear_sky: 25.04325,
  },
  {
    Day: 20,
    kWh: 3.302,
    kWh_predict: 4.65225,
    clear_sky: 25.37625,
  },
  {
    Day: 21,
    kWh: 3.441,
    kWh_predict: 1.77925,
    clear_sky: 25.72075,
  },
  {
    Day: 22,
    kWh: 2.71375,
    kWh_predict: 2.40425,
    clear_sky: 26.07125,
  },
  {
    Day: 23,
    kWh: 15.206,
    kWh_predict: 9.11725,
    clear_sky: 26.415,
  },
  {
    Day: 24,
    kWh: 1.1845,
    kWh_predict: 2.29675,
    clear_sky: 26.75075,
  },
  {
    Day: 25,
    kWh: 2.46925,
    kWh_predict: 5.546,
    clear_sky: 27.0775,
  },
  {
    Day: 26,
    kWh: 10.048,
    kWh_predict: 7.12325,
    clear_sky: 27.40675,
  },
  {
    Day: 27,
    kWh: 4.18075,
    kWh_predict: 11.0995,
    clear_sky: 27.7345,
  },
  {
    Day: 28,
    kWh: 11.91875,
    kWh_predict: 14.463,
    clear_sky: 28.055,
  },
  {
    Day: 29,
    kWh: 24.04925,
    kWh_predict: 15.879,
    clear_sky: 28.38075,
  },
  {
    Day: 30,
    kWh: 3.77025,
    kWh_predict: 6.38625,
    clear_sky: 28.704,
  },
  {
    Day: 31,
    kWh: 2.6575,
    kWh_predict: 2.85475,
    clear_sky: 29.02575,
  },
  {
    Day: 32,
    kWh: 8.84825,
    kWh_predict: 9.36975,
    clear_sky: 29.34375,
  },
  {
    Day: 33,
    kWh: 5.512,
    kWh_predict: 2.76,
    clear_sky: 29.66575,
  },
  {
    Day: 34,
    kWh: 7.17225,
    kWh_predict: 5.91925,
    clear_sky: 29.985,
  },
  {
    Day: 35,
    kWh: 3.00175,
    kWh_predict: 10.01375,
    clear_sky: 30.30425,
  },
  {
    Day: 36,
    kWh: 5.52525,
    kWh_predict: 12.35025,
    clear_sky: 30.617,
  },
  {
    Day: 37,
    kWh: 16.65525,
    kWh_predict: 17.48925,
    clear_sky: 30.931,
  },
  {
    Day: 38,
    kWh: 30.841,
    kWh_predict: 25.50425,
    clear_sky: 31.24925,
  },
  {
    Day: 39,
    kWh: 2.7445,
    kWh_predict: 6.75575,
    clear_sky: 31.55925,
  },
  {
    Day: 40,
    kWh: 1.82775,
    kWh_predict: 4.36425,
    clear_sky: 31.872,
  },
  {
    Day: 41,
    kWh: 15.9965,
    kWh_predict: 16.34475,
    clear_sky: 32.17625,
  },
  {
    Day: 42,
    kWh: 22.8745,
    kWh_predict: 23.76175,
    clear_sky: 32.4815,
  },
  {
    Day: 43,
    kWh: 24.498,
    kWh_predict: 22.19225,
    clear_sky: 32.78975,
  },
  {
    Day: 44,
    kWh: 8.259,
    kWh_predict: 9.57025,
    clear_sky: 33.09625,
  },
  {
    Day: 45,
    kWh: 16.1935,
    kWh_predict: 16.9825,
    clear_sky: 33.397,
  },
  {
    Day: 46,
    kWh: 6.43525,
    kWh_predict: 9.05,
    clear_sky: 33.6485,
  },
  {
    Day: 47,
    kWh: 1.93025,
    kWh_predict: 1.7155,
    clear_sky: 33.8485,
  },
  {
    Day: 48,
    kWh: 15.57025,
    kWh_predict: 13.6335,
    clear_sky: 34.04975,
  },
  {
    Day: 49,
    kWh: 21.073,
    kWh_predict: 19.62875,
    clear_sky: 34.25325,
  },
  {
    Day: 50,
    kWh: 23.629,
    kWh_predict: 16.4345,
    clear_sky: 34.459,
  },
  {
    Day: 51,
    kWh: 7.3065,
    kWh_predict: 10.714,
    clear_sky: 34.6645,
  },
  {
    Day: 52,
    kWh: 14.756,
    kWh_predict: 15.8945,
    clear_sky: 34.87125,
  },
  {
    Day: 53,
    kWh: 1.9515,
    kWh_predict: 5.2255,
    clear_sky: 35.0805,
  },
  {
    Day: 54,
    kWh: 1.81925,
    kWh_predict: 2.66875,
    clear_sky: 35.29,
  },
  {
    Day: 55,
    kWh: 3.07025,
    kWh_predict: 5.9275,
    clear_sky: 35.4945,
  },
  {
    Day: 56,
    kWh: 16.6395,
    kWh_predict: 21.661,
    clear_sky: 35.699,
  },
  {
    Day: 57,
    kWh: 10.1995,
    kWh_predict: 16.01025,
    clear_sky: 35.8975,
  },
  {
    Day: 58,
    kWh: 3.18625,
    kWh_predict: 4.781,
    clear_sky: 36.0965,
  },
  {
    Day: 59,
    kWh: 7.075,
    kWh_predict: 10.77975,
    clear_sky: 36.29625,
  },
  {
    Day: 60,
    kWh: 12.10575,
    kWh_predict: 12.29975,
    clear_sky: 36.4955,
  },
  {
    Day: 61,
    kWh: 16.042,
    kWh_predict: 12.49475,
    clear_sky: 36.69425,
  },
  {
    Day: 62,
    kWh: 6.5945,
    kWh_predict: 5.441,
    clear_sky: 36.894,
  },
  {
    Day: 63,
    kWh: 26.57675,
    kWh_predict: 22.5975,
    clear_sky: 37.09325,
  },
  {
    Day: 64,
    kWh: 19.85275,
    kWh_predict: 18.6,
    clear_sky: 37.29225,
  },
  {
    Day: 65,
    kWh: 1.1825,
    kWh_predict: 3.58375,
    clear_sky: 37.4915,
  },
  {
    Day: 66,
    kWh: 15.35325,
    kWh_predict: 13.13975,
    clear_sky: 37.69,
  },
  {
    Day: 67,
    kWh: 16.987,
    kWh_predict: 16.7285,
    clear_sky: 37.88375,
  },
  {
    Day: 69,
    kWh: 15.11375,
    kWh_predict: 17.59625,
    clear_sky: 38.26225,
  },
  {
    Day: 70,
    kWh: 2.8585,
    kWh_predict: 2.45,
    clear_sky: 38.45025,
  },
  {
    Day: 71,
    kWh: 7.685,
    kWh_predict: 6.368,
    clear_sky: 38.6345,
  },
  {
    Day: 72,
    kWh: 30.75125,
    kWh_predict: 28.747,
    clear_sky: 38.81775,
  },
  {
    Day: 73,
    kWh: 23.66975,
    kWh_predict: 23.26075,
    clear_sky: 38.99975,
  },
  {
    Day: 74,
    kWh: 16.876,
    kWh_predict: 17.1625,
    clear_sky: 39.181,
  },
  {
    Day: 75,
    kWh: 7.969,
    kWh_predict: 10.4995,
    clear_sky: 39.361,
  },
  {
    Day: 76,
    kWh: 19.38125,
    kWh_predict: 17.1745,
    clear_sky: 39.58925,
  },
  {
    Day: 77,
    kWh: 37.49575,
    kWh_predict: 27.1625,
    clear_sky: 39.859,
  },
  {
    Day: 78,
    kWh: 30.03975,
    kWh_predict: 24.507,
    clear_sky: 40.1185,
  },
  {
    Day: 79,
    kWh: 21.80225,
    kWh_predict: 9.15025,
    clear_sky: 40.3765,
  },
  {
    Day: 80,
    kWh: 4.61225,
    kWh_predict: 16.60725,
    clear_sky: 40.63275,
  },
  {
    Day: 81,
    kWh: 37.84225,
    kWh_predict: 34.446,
    clear_sky: 40.8855,
  },
  {
    Day: 82,
    kWh: 40.52975,
    kWh_predict: 37.7605,
    clear_sky: 41.13825,
  },
  {
    Day: 83,
    kWh: 40.51425,
    kWh_predict: 38.701,
    clear_sky: 41.38775,
  },
  {
    Day: 84,
    kWh: 40.151,
    kWh_predict: 39.3695,
    clear_sky: 41.634,
  },
  {
    Day: 85,
    kWh: 40.43175,
    kWh_predict: 39.38275,
    clear_sky: 41.86225,
  },
  {
    Day: 86,
    kWh: 39.96975,
    kWh_predict: 39.7305,
    clear_sky: 42.08775,
  },
  {
    Day: 87,
    kWh: 38.14625,
    kWh_predict: 38.127,
    clear_sky: 42.31075,
  },
  {
    Day: 88,
    kWh: 32.29975,
    kWh_predict: 27.6835,
    clear_sky: 42.532,
  },
  {
    Day: 89,
    kWh: 35.046,
    kWh_predict: 31.193,
    clear_sky: 42.75025,
  },
  {
    Day: 90,
    kWh: 25.08925,
    kWh_predict: 28.5575,
    clear_sky: 42.9675,
  },
  {
    Day: 91,
    kWh: 40.9765,
    kWh_predict: 39.5715,
    clear_sky: 43.18075,
  },
  {
    Day: 92,
    kWh: 40.8365,
    kWh_predict: 39.49975,
    clear_sky: 43.39225,
  },
  {
    Day: 93,
    kWh: 24.99875,
    kWh_predict: 25.1395,
    clear_sky: 43.5955,
  },
  {
    Day: 94,
    kWh: 29.0405,
    kWh_predict: 31.44,
    clear_sky: 43.793,
  },
  {
    Day: 95,
    kWh: 34.5305,
    kWh_predict: 37.28975,
    clear_sky: 43.9865,
  },
  {
    Day: 96,
    kWh: 41.145,
    kWh_predict: 41.11275,
    clear_sky: 44.178,
  },
  {
    Day: 97,
    kWh: 24.518,
    kWh_predict: 30.241,
    clear_sky: 44.36825,
  },
  {
    Day: 98,
    kWh: 39.9605,
    kWh_predict: 35.53775,
    clear_sky: 44.55425,
  },
  {
    Day: 99,
    kWh: 39.43575,
    kWh_predict: 32.0985,
    clear_sky: 44.7385,
  },
  {
    Day: 100,
    kWh: 35.133,
    kWh_predict: 35.58775,
    clear_sky: 44.91975,
  },
  {
    Day: 101,
    kWh: 40.47425,
    kWh_predict: 39,
    clear_sky: 45.0995,
  },
  {
    Day: 102,
    kWh: 41.047,
    kWh_predict: 42.38975,
    clear_sky: 45.27525,
  },
  {
    Day: 103,
    kWh: 32.63475,
    kWh_predict: 40.1075,
    clear_sky: 45.4435,
  },
  {
    Day: 104,
    kWh: 30.0835,
    kWh_predict: 23.917,
    clear_sky: 45.601,
  },
  {
    Day: 105,
    kWh: 22.587,
    kWh_predict: 23.95725,
    clear_sky: 45.7585,
  },
  {
    Day: 106,
    kWh: 41.5375,
    kWh_predict: 39.09175,
    clear_sky: 45.913,
  },
  {
    Day: 107,
    kWh: 38.33875,
    kWh_predict: 38.668,
    clear_sky: 46.022,
  },
  {
    Day: 108,
    kWh: 38.55575,
    kWh_predict: 36.997,
    clear_sky: 46.1295,
  },
  {
    Day: 109,
    kWh: 11.951,
    kWh_predict: 13.28175,
    clear_sky: 46.23525,
  },
  {
    Day: 110,
    kWh: 32.16975,
    kWh_predict: 34.9855,
    clear_sky: 46.3395,
  },
  {
    Day: 111,
    kWh: 42.8845,
    kWh_predict: 43.8065,
    clear_sky: 46.44275,
  },
  {
    Day: 112,
    kWh: 43.0485,
    kWh_predict: 44.35425,
    clear_sky: 46.54325,
  },
  {
    Day: 113,
    kWh: 42.93625,
    kWh_predict: 42.98,
    clear_sky: 46.64375,
  },
  {
    Day: 114,
    kWh: 42.6305,
    kWh_predict: 43.4625,
    clear_sky: 46.7405,
  },
  {
    Day: 115,
    kWh: 41.63775,
    kWh_predict: 36.96125,
    clear_sky: 46.8365,
  },
  {
    Day: 116,
    kWh: 33.17925,
    kWh_predict: 33.22775,
    clear_sky: 46.931,
  },
  {
    Day: 117,
    kWh: 40.052,
    kWh_predict: 37.61175,
    clear_sky: 47.024,
  },
  {
    Day: 118,
    kWh: 40.088,
    kWh_predict: 38.30875,
    clear_sky: 47.11425,
  },
  {
    Day: 119,
    kWh: 8.30425,
    kWh_predict: 16.29,
    clear_sky: 47.2025,
  },
  {
    Day: 120,
    kWh: 14.2685,
    kWh_predict: 26.2915,
    clear_sky: 47.29,
  },
  {
    Day: 121,
    kWh: 30.44175,
    kWh_predict: 33.46675,
    clear_sky: 47.375,
  },
  {
    Day: 122,
    kWh: 21.937,
    kWh_predict: 22.78775,
    clear_sky: 47.45875,
  },
  {
    Day: 123,
    kWh: 31.302,
    kWh_predict: 31.63325,
    clear_sky: 47.54025,
  },
  {
    Day: 124,
    kWh: 17.63675,
    kWh_predict: 28.847,
    clear_sky: 47.61975,
  },
  {
    Day: 125,
    kWh: 26.966,
    kWh_predict: 26.7395,
    clear_sky: 47.698,
  },
  {
    Day: 126,
    kWh: 41.36275,
    kWh_predict: 39.0395,
    clear_sky: 47.77375,
  },
  {
    Day: 127,
    kWh: 43.801,
    kWh_predict: 43.99,
    clear_sky: 47.848,
  },
  {
    Day: 128,
    kWh: 43.39175,
    kWh_predict: 41.69975,
    clear_sky: 47.92175,
  },
  {
    Day: 129,
    kWh: 36.2125,
    kWh_predict: 38.43625,
    clear_sky: 47.9915,
  },
  {
    Day: 130,
    kWh: 42.89275,
    kWh_predict: 41.20575,
    clear_sky: 48.06025,
  },
  {
    Day: 131,
    kWh: 10.34325,
    kWh_predict: 15.1285,
    clear_sky: 48.12725,
  },
  {
    Day: 132,
    kWh: 32.22125,
    kWh_predict: 29.03375,
    clear_sky: 48.1925,
  },
  {
    Day: 133,
    kWh: 31.81175,
    kWh_predict: 30.17525,
    clear_sky: 48.25575,
  },
  {
    Day: 134,
    kWh: 31.63825,
    kWh_predict: 39.89425,
    clear_sky: 48.31725,
  },
  {
    Day: 135,
    kWh: 37.9705,
    kWh_predict: 32.018,
    clear_sky: 48.37675,
  },
  {
    Day: 136,
    kWh: 44.71425,
    kWh_predict: 41.1445,
    clear_sky: 48.43425,
  },
  {
    Day: 137,
    kWh: 35.96575,
    kWh_predict: 35.27075,
    clear_sky: 48.49875,
  },
  {
    Day: 138,
    kWh: 38.7545,
    kWh_predict: 40.67775,
    clear_sky: 48.56875,
  },
  {
    Day: 139,
    kWh: 43.39975,
    kWh_predict: 33.54825,
    clear_sky: 48.6385,
  },
  {
    Day: 140,
    kWh: 42.00525,
    kWh_predict: 38.9115,
    clear_sky: 48.70575,
  },
  {
    Day: 141,
    kWh: 36.49125,
    kWh_predict: 35.657,
    clear_sky: 48.76975,
  },
  {
    Day: 142,
    kWh: 40.039,
    kWh_predict: 38.3575,
    clear_sky: 48.83275,
  },
  {
    Day: 143,
    kWh: 20.45975,
    kWh_predict: 24.39,
    clear_sky: 48.893,
  },
  {
    Day: 144,
    kWh: 31.3865,
    kWh_predict: 31.50175,
    clear_sky: 48.951,
  },
  {
    Day: 145,
    kWh: 18.2535,
    kWh_predict: 21.56525,
    clear_sky: 49.00575,
  },
  {
    Day: 146,
    kWh: 40.25425,
    kWh_predict: 39.759,
    clear_sky: 49.05975,
  },
  {
    Day: 147,
    kWh: 43.28925,
    kWh_predict: 40.272,
    clear_sky: 49.11125,
  },
  {
    Day: 148,
    kWh: 43.90225,
    kWh_predict: 40.31925,
    clear_sky: 49.16075,
  },
  {
    Day: 149,
    kWh: 44.80425,
    kWh_predict: 43.25125,
    clear_sky: 49.207,
  },
  {
    Day: 150,
    kWh: 44.548,
    kWh_predict: 42.6165,
    clear_sky: 49.253,
  },
  {
    Day: 151,
    kWh: 44.2845,
    kWh_predict: 45.212,
    clear_sky: 49.29625,
  },
  {
    Day: 152,
    kWh: 44.5905,
    kWh_predict: 43.2675,
    clear_sky: 49.33725,
  },
  {
    Day: 153,
    kWh: 42.0465,
    kWh_predict: 39.071,
    clear_sky: 49.37725,
  },
  {
    Day: 154,
    kWh: 43.895,
    kWh_predict: 27.183,
    clear_sky: 49.41325,
  },
  {
    Day: 155,
    kWh: 37.5395,
    kWh_predict: 27.42925,
    clear_sky: 49.449,
  },
  {
    Day: 156,
    kWh: 16.77525,
    kWh_predict: 17.22025,
    clear_sky: 49.4805,
  },
  {
    Day: 157,
    kWh: 17.12,
    kWh_predict: 19.89375,
    clear_sky: 49.511,
  },
  {
    Day: 158,
    kWh: 31.1345,
    kWh_predict: 31.389,
    clear_sky: 49.5395,
  },
  {
    Day: 159,
    kWh: 35.926,
    kWh_predict: 30.64225,
    clear_sky: 49.565,
  },
  {
    Day: 160,
    kWh: 6.9945,
    kWh_predict: 9.315,
    clear_sky: 49.59,
  },
  {
    Day: 161,
    kWh: 24.28825,
    kWh_predict: 33.4865,
    clear_sky: 49.61175,
  },
  {
    Day: 162,
    kWh: 44.45825,
    kWh_predict: 38.16775,
    clear_sky: 49.63175,
  },
  {
    Day: 163,
    kWh: 33.79675,
    kWh_predict: 23.2075,
    clear_sky: 49.64875,
  },
  {
    Day: 164,
    kWh: 27.85925,
    kWh_predict: 30.2725,
    clear_sky: 49.6655,
  },
  {
    Day: 165,
    kWh: 36.9665,
    kWh_predict: 37.1335,
    clear_sky: 49.6795,
  },
  {
    Day: 166,
    kWh: 35.8295,
    kWh_predict: 25.34525,
    clear_sky: 49.6915,
  },
  {
    Day: 167,
    kWh: 27.934,
    kWh_predict: 36.47075,
    clear_sky: 49.701,
  },
  {
    Day: 168,
    kWh: 25.3775,
    kWh_predict: 27.65575,
    clear_sky: 49.70525,
  },
  {
    Day: 169,
    kWh: 16.468,
    kWh_predict: 19.929,
    clear_sky: 49.70675,
  },
  {
    Day: 170,
    kWh: 20.1015,
    kWh_predict: 28.919,
    clear_sky: 49.70825,
  },
  {
    Day: 171,
    kWh: 31.8505,
    kWh_predict: 29.249,
    clear_sky: 49.706,
  },
  {
    Day: 172,
    kWh: 35.75825,
    kWh_predict: 30.97325,
    clear_sky: 49.70175,
  },
  {
    Day: 173,
    kWh: 24.4265,
    kWh_predict: 25.117,
    clear_sky: 49.69625,
  },
  {
    Day: 174,
    kWh: 42.2335,
    kWh_predict: 42.05575,
    clear_sky: 49.6885,
  },
  {
    Day: 175,
    kWh: 43.93325,
    kWh_predict: 45.1995,
    clear_sky: 49.6785,
  },
  {
    Day: 176,
    kWh: 43.36125,
    kWh_predict: 43.58675,
    clear_sky: 49.66675,
  },
  {
    Day: 177,
    kWh: 43.87575,
    kWh_predict: 45.93675,
    clear_sky: 49.6525,
  },
  {
    Day: 178,
    kWh: 27.10075,
    kWh_predict: 33.48375,
    clear_sky: 49.636,
  },
  {
    Day: 179,
    kWh: 20.06075,
    kWh_predict: 23.93325,
    clear_sky: 49.618,
  },
  {
    Day: 180,
    kWh: 33.2435,
    kWh_predict: 35.547,
    clear_sky: 49.59825,
  },
  {
    Day: 181,
    kWh: 26.7365,
    kWh_predict: 28.7555,
    clear_sky: 49.5765,
  },
  {
    Day: 182,
    kWh: 11.96425,
    kWh_predict: 15.04675,
    clear_sky: 49.55275,
  },
  {
    Day: 183,
    kWh: 29.78825,
    kWh_predict: 19.143,
    clear_sky: 49.5265,
  },
  {
    Day: 184,
    kWh: 23.15,
    kWh_predict: 27.1055,
    clear_sky: 49.49875,
  },
  {
    Day: 185,
    kWh: 33.89,
    kWh_predict: 26.086,
    clear_sky: 49.46825,
  },
  {
    Day: 186,
    kWh: 7.6265,
    kWh_predict: 11.70975,
    clear_sky: 49.43675,
  },
  {
    Day: 187,
    kWh: 22.486,
    kWh_predict: 20.0055,
    clear_sky: 49.40225,
  },
  {
    Day: 188,
    kWh: 31.77925,
    kWh_predict: 35.7755,
    clear_sky: 49.366,
  },
  {
    Day: 189,
    kWh: 34.4405,
    kWh_predict: 33.35175,
    clear_sky: 49.32775,
  },
  {
    Day: 190,
    kWh: 7.693,
    kWh_predict: 11.3395,
    clear_sky: 49.28725,
  },
  {
    Day: 191,
    kWh: 15.376,
    kWh_predict: 14.24675,
    clear_sky: 49.24525,
  },
  {
    Day: 192,
    kWh: 26.47525,
    kWh_predict: 26.12425,
    clear_sky: 49.199,
  },
  {
    Day: 193,
    kWh: 36.962,
    kWh_predict: 37.57275,
    clear_sky: 49.15325,
  },
  {
    Day: 194,
    kWh: 33.9375,
    kWh_predict: 35.25925,
    clear_sky: 49.105,
  },
  {
    Day: 195,
    kWh: 43.98225,
    kWh_predict: 42.1005,
    clear_sky: 49.05325,
  },
  {
    Day: 196,
    kWh: 10.7225,
    kWh_predict: 13.817,
    clear_sky: 48.99975,
  },
  {
    Day: 197,
    kWh: 22.368,
    kWh_predict: 27.126,
    clear_sky: 48.945,
  },
  {
    Day: 198,
    kWh: 17.76225,
    kWh_predict: 20.79325,
    clear_sky: 48.88625,
  },
  {
    Day: 199,
    kWh: 34.1935,
    kWh_predict: 28.03625,
    clear_sky: 48.8255,
  },
  {
    Day: 200,
    kWh: 34.28975,
    kWh_predict: 23.776,
    clear_sky: 48.761,
  },
  {
    Day: 201,
    kWh: 22.7915,
    kWh_predict: 21.26575,
    clear_sky: 48.69575,
  },
  {
    Day: 202,
    kWh: 43.313,
    kWh_predict: 37.9565,
    clear_sky: 48.62975,
  },
  {
    Day: 203,
    kWh: 34.48475,
    kWh_predict: 39.01925,
    clear_sky: 48.559,
  },
  {
    Day: 204,
    kWh: 39.24075,
    kWh_predict: 36.79675,
    clear_sky: 48.487,
  },
  {
    Day: 205,
    kWh: 38.976,
    kWh_predict: 36.81825,
    clear_sky: 48.41275,
  },
  {
    Day: 206,
    kWh: 20.93425,
    kWh_predict: 26.2915,
    clear_sky: 48.33775,
  },
  {
    Day: 207,
    kWh: 17.084,
    kWh_predict: 15.3155,
    clear_sky: 48.259,
  },
  {
    Day: 208,
    kWh: 31.12725,
    kWh_predict: 26.60375,
    clear_sky: 48.17675,
  },
  {
    Day: 209,
    kWh: 20.61325,
    kWh_predict: 26.23175,
    clear_sky: 48.09225,
  },
  {
    Day: 210,
    kWh: 33.02725,
    kWh_predict: 34.88475,
    clear_sky: 48.005,
  },
  {
    Day: 211,
    kWh: 39.88025,
    kWh_predict: 41.04675,
    clear_sky: 47.91725,
  },
  {
    Day: 212,
    kWh: 41.99875,
    kWh_predict: 39.6045,
    clear_sky: 47.82525,
  },
  {
    Day: 213,
    kWh: 42.7615,
    kWh_predict: 44.366,
    clear_sky: 47.73275,
  },
  {
    Day: 214,
    kWh: 32.36525,
    kWh_predict: 25,
    clear_sky: 47.638,
  },
  {
    Day: 215,
    kWh: 33.21625,
    kWh_predict: 32.61375,
    clear_sky: 47.5405,
  },
  {
    Day: 216,
    kWh: 17.65075,
    kWh_predict: 21.92375,
    clear_sky: 47.441,
  },
  {
    Day: 217,
    kWh: 38.02025,
    kWh_predict: 37.98175,
    clear_sky: 47.339,
  },
  {
    Day: 218,
    kWh: 42.564,
    kWh_predict: 41.50175,
    clear_sky: 47.23425,
  },
  {
    Day: 219,
    kWh: 38.14075,
    kWh_predict: 35.41025,
    clear_sky: 47.12825,
  },
  {
    Day: 220,
    kWh: 41.50025,
    kWh_predict: 44.14725,
    clear_sky: 47.01925,
  },
  {
    Day: 221,
    kWh: 35.104,
    kWh_predict: 36.70575,
    clear_sky: 46.909,
  },
  {
    Day: 222,
    kWh: 38.9275,
    kWh_predict: 32.00075,
    clear_sky: 46.79575,
  },
  {
    Day: 223,
    kWh: 34.922,
    kWh_predict: 35.13825,
    clear_sky: 46.68075,
  },
  {
    Day: 224,
    kWh: 20.4345,
    kWh_predict: 28.27075,
    clear_sky: 46.563,
  },
  {
    Day: 225,
    kWh: 36.74875,
    kWh_predict: 35.661,
    clear_sky: 46.4425,
  },
  {
    Day: 226,
    kWh: 37.87475,
    kWh_predict: 29.509,
    clear_sky: 46.3205,
  },
  {
    Day: 227,
    kWh: 11.5725,
    kWh_predict: 19.396,
    clear_sky: 46.19625,
  },
  {
    Day: 228,
    kWh: 16.8125,
    kWh_predict: 23.084,
    clear_sky: 46.06475,
  },
  {
    Day: 229,
    kWh: 21.88275,
    kWh_predict: 17.07925,
    clear_sky: 45.949,
  },
  {
    Day: 230,
    kWh: 23.55325,
    kWh_predict: 25.494,
    clear_sky: 45.84675,
  },
  {
    Day: 231,
    kWh: 26.6505,
    kWh_predict: 28.82925,
    clear_sky: 45.743,
  },
  {
    Day: 232,
    kWh: 24.8895,
    kWh_predict: 27.47725,
    clear_sky: 45.6365,
  },
  {
    Day: 233,
    kWh: 25.59075,
    kWh_predict: 22.35975,
    clear_sky: 45.52875,
  },
  {
    Day: 234,
    kWh: 37.5235,
    kWh_predict: 34.76725,
    clear_sky: 45.41925,
  },
  {
    Day: 235,
    kWh: 24.8825,
    kWh_predict: 26.1935,
    clear_sky: 45.30775,
  },
  {
    Day: 236,
    kWh: 22.662,
    kWh_predict: 24.854,
    clear_sky: 45.195,
  },
  {
    Day: 237,
    kWh: 28.571,
    kWh_predict: 26.4585,
    clear_sky: 45.08,
  },
  {
    Day: 238,
    kWh: 10.353,
    kWh_predict: 13.735,
    clear_sky: 44.9625,
  },
  {
    Day: 239,
    kWh: 9.09125,
    kWh_predict: 19.13475,
    clear_sky: 44.8435,
  },
  {
    Day: 240,
    kWh: 20.78025,
    kWh_predict: 24.38775,
    clear_sky: 44.723,
  },
  {
    Day: 241,
    kWh: 21.48725,
    kWh_predict: 24.3615,
    clear_sky: 44.6015,
  },
  {
    Day: 242,
    kWh: 18.9865,
    kWh_predict: 20.723,
    clear_sky: 44.479,
  },
  {
    Day: 243,
    kWh: 12.76625,
    kWh_predict: 14.81175,
    clear_sky: 44.35425,
  },
  {
    Day: 244,
    kWh: 14.05825,
    kWh_predict: 26.174,
    clear_sky: 44.2275,
  },
  {
    Day: 245,
    kWh: 15.35475,
    kWh_predict: 27.2365,
    clear_sky: 44.1,
  },
  {
    Day: 246,
    kWh: 25.494,
    kWh_predict: 25.631,
    clear_sky: 43.97075,
  },
  {
    Day: 247,
    kWh: 11.56775,
    kWh_predict: 15.826,
    clear_sky: 43.83875,
  },
  {
    Day: 248,
    kWh: 23.9995,
    kWh_predict: 20.5485,
    clear_sky: 43.70575,
  },
  {
    Day: 249,
    kWh: 29.4685,
    kWh_predict: 30.319,
    clear_sky: 43.5695,
  },
  {
    Day: 250,
    kWh: 34.7555,
    kWh_predict: 32.00725,
    clear_sky: 43.427,
  },
  {
    Day: 251,
    kWh: 28.70475,
    kWh_predict: 17.34625,
    clear_sky: 43.28325,
  },
  {
    Day: 252,
    kWh: 6.776,
    kWh_predict: 10.4635,
    clear_sky: 43.13825,
  },
  {
    Day: 253,
    kWh: 26.89,
    kWh_predict: 21.1175,
    clear_sky: 42.99175,
  },
  {
    Day: 254,
    kWh: 36.165,
    kWh_predict: 33.4425,
    clear_sky: 42.84325,
  },
  {
    Day: 255,
    kWh: 33.977,
    kWh_predict: 29.94775,
    clear_sky: 42.69325,
  },
  {
    Day: 256,
    kWh: 31.20825,
    kWh_predict: 26.4885,
    clear_sky: 42.538,
  },
  {
    Day: 257,
    kWh: 35.86675,
    kWh_predict: 26.07775,
    clear_sky: 42.382,
  },
  {
    Day: 258,
    kWh: 36.63475,
    kWh_predict: 36.558,
    clear_sky: 42.22425,
  },
  {
    Day: 259,
    kWh: 35.94625,
    kWh_predict: 35.5535,
    clear_sky: 42.065,
  },
  {
    Day: 260,
    kWh: 29.31125,
    kWh_predict: 23.18825,
    clear_sky: 41.91125,
  },
  {
    Day: 261,
    kWh: 37.6115,
    kWh_predict: 32.56,
    clear_sky: 41.7565,
  },
  {
    Day: 262,
    kWh: 37.3985,
    kWh_predict: 34.84725,
    clear_sky: 41.60125,
  },
  {
    Day: 263,
    kWh: 34.23725,
    kWh_predict: 34.377,
    clear_sky: 41.444,
  },
  {
    Day: 264,
    kWh: 33.704,
    kWh_predict: 31.1655,
    clear_sky: 41.2785,
  },
  {
    Day: 265,
    kWh: 37.02625,
    kWh_predict: 32.1985,
    clear_sky: 41.113,
  },
  {
    Day: 266,
    kWh: 35.51525,
    kWh_predict: 28.26275,
    clear_sky: 40.94525,
  },
  {
    Day: 267,
    kWh: 10.11575,
    kWh_predict: 14.9735,
    clear_sky: 40.7775,
  },
  {
    Day: 268,
    kWh: 20.37375,
    kWh_predict: 17.80475,
    clear_sky: 40.6075,
  },
  {
    Day: 269,
    kWh: 11.437,
    kWh_predict: 10.36775,
    clear_sky: 40.43575,
  },
  {
    Day: 270,
    kWh: 7.6965,
    kWh_predict: 8.696,
    clear_sky: 40.26325,
  },
  {
    Day: 271,
    kWh: 7.9275,
    kWh_predict: 10.40425,
    clear_sky: 40.09075,
  },
  {
    Day: 272,
    kWh: 0.883,
    kWh_predict: 4.92775,
    clear_sky: 39.9155,
  },
  {
    Day: 273,
    kWh: 12.338,
    kWh_predict: 14.5195,
    clear_sky: 39.74025,
  },
  {
    Day: 274,
    kWh: 9.19025,
    kWh_predict: 9.635,
    clear_sky: 39.56375,
  },
  {
    Day: 275,
    kWh: 7.47225,
    kWh_predict: 9.649,
    clear_sky: 39.38575,
  },
  {
    Day: 276,
    kWh: 6.83925,
    kWh_predict: 7.8025,
    clear_sky: 39.205,
  },
  {
    Day: 277,
    kWh: 7.2355,
    kWh_predict: 9.86,
    clear_sky: 39.019,
  },
  {
    Day: 278,
    kWh: 10.988,
    kWh_predict: 8.68225,
    clear_sky: 38.83125,
  },
  {
    Day: 279,
    kWh: 10.37425,
    kWh_predict: 9.73275,
    clear_sky: 38.6435,
  },
  {
    Day: 280,
    kWh: 18.41825,
    kWh_predict: 16.78625,
    clear_sky: 38.45525,
  },
  {
    Day: 281,
    kWh: 14.55675,
    kWh_predict: 19.11575,
    clear_sky: 38.264,
  },
  {
    Day: 282,
    kWh: 3.946,
    kWh_predict: 4.57375,
    clear_sky: 38.06975,
  },
  {
    Day: 283,
    kWh: 17.6775,
    kWh_predict: 17.7425,
    clear_sky: 37.8745,
  },
  {
    Day: 284,
    kWh: 15.713,
    kWh_predict: 17.69925,
    clear_sky: 37.67925,
  },
  {
    Day: 285,
    kWh: 18.74275,
    kWh_predict: 20.58825,
    clear_sky: 37.4835,
  },
  {
    Day: 286,
    kWh: 13.283,
    kWh_predict: 23.24975,
    clear_sky: 37.28775,
  },
  {
    Day: 287,
    kWh: 4.294,
    kWh_predict: 6.20325,
    clear_sky: 37.0905,
  },
  {
    Day: 288,
    kWh: 11.19275,
    kWh_predict: 12.50025,
    clear_sky: 36.88725,
  },
  {
    Day: 289,
    kWh: 24.4145,
    kWh_predict: 21.51725,
    clear_sky: 36.68325,
  },
  {
    Day: 290,
    kWh: 14.2525,
    kWh_predict: 22.2935,
    clear_sky: 36.448,
  },
  {
    Day: 291,
    kWh: 10.39825,
    kWh_predict: 13.923,
    clear_sky: 36.18,
  },
  {
    Day: 292,
    kWh: 10.043,
    kWh_predict: 10.90475,
    clear_sky: 35.9115,
  },
  {
    Day: 293,
    kWh: 19.98625,
    kWh_predict: 15.152,
    clear_sky: 35.64325,
  },
  {
    Day: 294,
    kWh: 4.222,
    kWh_predict: 10.008,
    clear_sky: 35.366,
  },
  {
    Day: 295,
    kWh: 7.35925,
    kWh_predict: 10.13875,
    clear_sky: 35.0865,
  },
  {
    Day: 296,
    kWh: 13.90325,
    kWh_predict: 15.3555,
    clear_sky: 34.7995,
  },
  {
    Day: 297,
    kWh: 16.73975,
    kWh_predict: 14.40275,
    clear_sky: 34.51225,
  },
  {
    Day: 298,
    kWh: 9.946,
    kWh_predict: 12.0245,
    clear_sky: 34.226,
  },
  {
    Day: 299,
    kWh: 5.38475,
    kWh_predict: 11.906,
    clear_sky: 33.9405,
  },
  {
    Day: 300,
    kWh: 22.39025,
    kWh_predict: 13.65,
    clear_sky: 33.65675,
  },
  {
    Day: 301,
    kWh: 5.02425,
    kWh_predict: 5.66475,
    clear_sky: 33.36325,
  },
  {
    Day: 302,
    kWh: 5.6025,
    kWh_predict: 8.00275,
    clear_sky: 33.0645,
  },
  {
    Day: 303,
    kWh: 8.7035,
    kWh_predict: 12.07675,
    clear_sky: 32.7635,
  },
  {
    Day: 304,
    kWh: 3.99375,
    kWh_predict: 8.9405,
    clear_sky: 32.4635,
  },
  {
    Day: 305,
    kWh: 10.27075,
    kWh_predict: 13.01475,
    clear_sky: 32.166,
  },
  {
    Day: 306,
    kWh: 3.71525,
    kWh_predict: 5.642,
    clear_sky: 31.86825,
  },
  {
    Day: 307,
    kWh: 4.7175,
    kWh_predict: 5.599,
    clear_sky: 31.56425,
  },
  {
    Day: 308,
    kWh: 17.6665,
    kWh_predict: 14.35875,
    clear_sky: 31.25375,
  },
  {
    Day: 309,
    kWh: 26.98325,
    kWh_predict: 22.98875,
    clear_sky: 30.94325,
  },
  {
    Day: 310,
    kWh: 22.74,
    kWh_predict: 18.35175,
    clear_sky: 30.63775,
  },
  {
    Day: 311,
    kWh: 27.6845,
    kWh_predict: 21.842,
    clear_sky: 30.332,
  },
  {
    Day: 312,
    kWh: 23.31875,
    kWh_predict: 20.9965,
    clear_sky: 30.0205,
  },
  {
    Day: 313,
    kWh: 14.2965,
    kWh_predict: 10.486,
    clear_sky: 29.705,
  },
  {
    Day: 314,
    kWh: 20.29425,
    kWh_predict: 12.40675,
    clear_sky: 29.39,
  },
  {
    Day: 315,
    kWh: 6.56875,
    kWh_predict: 7.8235,
    clear_sky: 29.0795,
  },
  {
    Day: 316,
    kWh: 5.08225,
    kWh_predict: 6.3285,
    clear_sky: 28.7625,
  },
  {
    Day: 317,
    kWh: 18.166,
    kWh_predict: 11.475,
    clear_sky: 28.444,
  },
  {
    Day: 318,
    kWh: 1.8275,
    kWh_predict: 5.5165,
    clear_sky: 28.126,
  },
  {
    Day: 319,
    kWh: 13.70675,
    kWh_predict: 10.786,
    clear_sky: 27.80725,
  },
  {
    Day: 320,
    kWh: 1.058,
    kWh_predict: 5.062,
    clear_sky: 27.489,
  },
  {
    Day: 321,
    kWh: 2.92475,
    kWh_predict: 7.8935,
    clear_sky: 27.18625,
  },
  {
    Day: 322,
    kWh: 2.07875,
    kWh_predict: 3.5125,
    clear_sky: 26.88325,
  },
  {
    Day: 323,
    kWh: 26.82275,
    kWh_predict: 18.3295,
    clear_sky: 26.58025,
  },
  {
    Day: 324,
    kWh: 3.5175,
    kWh_predict: 8.64925,
    clear_sky: 26.272,
  },
  {
    Day: 325,
    kWh: 7.64075,
    kWh_predict: 14.462,
    clear_sky: 25.952,
  },
  {
    Day: 326,
    kWh: 2.789,
    kWh_predict: 5.35725,
    clear_sky: 25.64025,
  },
  {
    Day: 327,
    kWh: 1.55325,
    kWh_predict: 5.7185,
    clear_sky: 25.336,
  },
  {
    Day: 328,
    kWh: 18.5105,
    kWh_predict: 16.25625,
    clear_sky: 25.041,
  },
  {
    Day: 329,
    kWh: 7.33625,
    kWh_predict: 9.07875,
    clear_sky: 24.75325,
  },
  {
    Day: 330,
    kWh: 15.8645,
    kWh_predict: 12.2135,
    clear_sky: 24.477,
  },
  {
    Day: 331,
    kWh: 1.40675,
    kWh_predict: 4.19575,
    clear_sky: 24.20875,
  },
  {
    Day: 332,
    kWh: 3.309,
    kWh_predict: 5.72875,
    clear_sky: 23.95075,
  },
  {
    Day: 333,
    kWh: 22.40325,
    kWh_predict: 15.1255,
    clear_sky: 23.702,
  },
  {
    Day: 334,
    kWh: 0.69525,
    kWh_predict: 2.41875,
    clear_sky: 23.4655,
  },
  {
    Day: 335,
    kWh: 1.311,
    kWh_predict: 1.61175,
    clear_sky: 23.2365,
  },
  {
    Day: 336,
    kWh: 9.804,
    kWh_predict: 7.796,
    clear_sky: 23.0205,
  },
  {
    Day: 337,
    kWh: 2.534,
    kWh_predict: 3.98975,
    clear_sky: 22.81375,
  },
  {
    Day: 338,
    kWh: 0.98,
    kWh_predict: 2.35825,
    clear_sky: 22.6205,
  },
  {
    Day: 339,
    kWh: 1.6235,
    kWh_predict: 2.42575,
    clear_sky: 22.43725,
  },
  {
    Day: 340,
    kWh: 1.83725,
    kWh_predict: 6.9555,
    clear_sky: 22.2655,
  },
  {
    Day: 341,
    kWh: 10.434,
    kWh_predict: 9.018,
    clear_sky: 22.10575,
  },
  {
    Day: 342,
    kWh: 4.10775,
    kWh_predict: 3.47175,
    clear_sky: 21.95775,
  },
  {
    Day: 343,
    kWh: 15.898,
    kWh_predict: 5.815,
    clear_sky: 21.82125,
  },
  {
    Day: 344,
    kWh: 0.98975,
    kWh_predict: 0.26525,
    clear_sky: 21.698,
  },
  {
    Day: 345,
    kWh: 9.70175,
    kWh_predict: 9.6365,
    clear_sky: 21.58725,
  },
  {
    Day: 346,
    kWh: 0.66925,
    kWh_predict: 0.34675,
    clear_sky: 21.4885,
  },
  {
    Day: 347,
    kWh: 2.17375,
    kWh_predict: 2.05375,
    clear_sky: 21.4025,
  },
  {
    Day: 348,
    kWh: 4.7145,
    kWh_predict: 4.04075,
    clear_sky: 21.329,
  },
  {
    Day: 349,
    kWh: 15.28325,
    kWh_predict: 8.27175,
    clear_sky: 21.2695,
  },
  {
    Day: 350,
    kWh: 11.4535,
    kWh_predict: 8.4935,
    clear_sky: 21.22325,
  },
  {
    Day: 351,
    kWh: 7.435,
    kWh_predict: 8.799,
    clear_sky: 21.153,
  },
  {
    Day: 352,
    kWh: 10.099,
    kWh_predict: 6.76525,
    clear_sky: 21.06025,
  },
  {
    Day: 353,
    kWh: 12.41975,
    kWh_predict: 10.9215,
    clear_sky: 20.981,
  },
  {
    Day: 354,
    kWh: 4.10325,
    kWh_predict: 6.602,
    clear_sky: 20.91475,
  },
  {
    Day: 355,
    kWh: 13.84075,
    kWh_predict: 6.561,
    clear_sky: 20.862,
  },
  {
    Day: 356,
    kWh: 0.38,
    kWh_predict: 0.924,
    clear_sky: 20.82275,
  },
  {
    Day: 357,
    kWh: 2.46225,
    kWh_predict: 3.823,
    clear_sky: 20.79725,
  },
  {
    Day: 358,
    kWh: 2.3425,
    kWh_predict: 2.92925,
    clear_sky: 20.7855,
  },
  {
    Day: 359,
    kWh: 3.066,
    kWh_predict: 3.7635,
    clear_sky: 20.78725,
  },
  {
    Day: 360,
    kWh: 12.32125,
    kWh_predict: 10.55875,
    clear_sky: 20.801,
  },
  {
    Day: 361,
    kWh: 2.05725,
    kWh_predict: 3.08325,
    clear_sky: 20.83,
  },
  {
    Day: 362,
    kWh: 0.482,
    kWh_predict: 0.976,
    clear_sky: 20.8715,
  },
  {
    Day: 363,
    kWh: 3.63875,
    kWh_predict: 2.876,
    clear_sky: 20.92725,
  },
  {
    Day: 364,
    kWh: 5.95825,
    kWh_predict: 5.9145,
    clear_sky: 20.99525,
  },
  {
    Day: 365,
    kWh: 4.8385,
    kWh_predict: 6.77375,
    clear_sky: 21.07625,
  },
  {
    Day: 366,
    kWh: 14.14775,
    kWh_predict: 6.195,
    clear_sky: 21.17075,
  },
];
