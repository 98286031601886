export const Monthly_kWh = [
    {
        "month": 'Jan',
        "kWh": 225.2,
        "kWh_predict": 212.6,
        "clear_sky": 21.2
    },
    {
        "month": 'Feb',
        "kWh": 307.2,
        "kWh_predict": 340.4,
        "clear_sky": 29.0
    },
    {
        "month": 'Mar',
        "kWh": 703.0,
        "kWh_predict": 660.5,
        "clear_sky": 36.5
    },
    {
        "month": 'Apr',
        "kWh": 1029.0,
        "kWh_predict": 1043.2,
        "clear_sky": 43.2
    },
    {
        "month": 'May',
        "kWh": 1092.5,
        "kWh_predict": 1086.5,
        "clear_sky": 47.4
    },
    {
        "month": 'Jun',
        "kWh": 941.7,
        "kWh_predict": 929.8,
        "clear_sky": 49.3
    },
    {
        "month": 'Jul',
        "kWh": 862.4,
        "kWh_predict": 850.3,
        "clear_sky": 49.6
    },
    {
        "month": 'Aug',
        "kWh": 848.3,
        "kWh_predict": 873.4,
        "clear_sky": 47.7
    },
    {
        "month": 'Sep',
        "kWh": 742.4,
        "kWh_predict": 713.0,
        "clear_sky": 44.2
    },
    {
        "month": 'Okt',
        "kWh": 352.3,
        "kWh_predict": 399.8,
        "clear_sky": 39.6
    },
    {
        "month": 'Nov',
        "kWh": 334.9,
        "kWh_predict": 321.6,
        "clear_sky": 32.2
    },
    {
        "month": 'Dec',
        "kWh": 179.0,
        "kWh_predict": 157.8,
        "clear_sky": 23.2
    }
]