import React from "react";
import styles from "./User.module.css";
import Card from "../components/UI/Card";
import Button from '../components/UI/Button'

import {useNavigate} from 'react-router-dom'

function printRoles(R) {
  return R.reduce((myRoles, role) => myRoles + ", " + role, "");
}

const User = (props) => {
    const navigate = useNavigate()

    function closeUserInfo(){
        console.log('executed close')
        navigate('/welcome')
    }

  return (
    props.user && (
      <div className={styles.userContainer}>
        <Card className={styles.userCard}>
          <h4>User info</h4>
        <hr/><br/>
          provider: {props.user.identityProvider}<br/>
          userId: {props.user.userId}<br/>
          Name: {props.user.userDetails}<br/>
          userRoles:{printRoles(props.user.userRoles)}<br/><br/>
          <Button handleClick={closeUserInfo}>OK</Button>
        </Card>
      </div>
    )
  );
};
export default User;
