import styles from './Coordinates.module.css'
import Entry from '../../Entry/Entry'

const tooltip = {
    Lat: "Your North-South position (North pole = +90° and South = -90°).<br/> eg: Brugge = +51.2092°",
    Lon: 'Your West-East position from Greenwich (0 upto +/-180°). eg: Brugge = +3.2248°',
}

const Coordinates = (props) => {

    return (
        <div className={styles.coordinates_container}>
            <h5 className={styles.title}>Location</h5>
            <Entry title='lat' value={props.home.lat.toFixed(4)} dim='deg' info={tooltip.Lat} />
            <Entry title='lng' value={props.home.lng.toFixed(4)} dim='deg' info={tooltip.Lon} />
        </div>
    )
}
export default Coordinates