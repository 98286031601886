import React,{useState} from 'react'
import styles from './Installation.module.css'
import Tabs from '../components/UI/Tabs/Tabs'
import Card from '../components/UI/Card'
import Form from '../components/InstallationPage/Form'
import Calendar from '../components/InstallationPage/Calendar-widget/Calendar'
import OverviewWidgets from '../components/InstallationPage/Overview-widgets/OverviewWidgets'
import {calcDaykWh} from '../services/solar/solarCalc'

import { useSelector, useDispatch } from "react-redux";
import {setDate} from "../features/installation/installationSlice"
import { getClearSky, getSolarForecast } from '../features/solarData/solarDataSlice'

// const defaultInstallation = {
//     user: 'John Doe',
//     date:"09-15-2022",
//     totalWattPeak: 7400,
//     wattInvertor: 5040,
//     azimuth: 180,
//     tilt: 35,
//     altitude:70,
//     location: {
//         lat:51.2404,
//         lng:3.2248
//     },
//     timezone:"Europe/Brussels"
// }

const Installation = (props)=>{
    const dispatch = useDispatch()
    const {installation,solarData} = useSelector((store) => store);

    const [startDate, setStartDate] = useState(new Date())

    const [maxkWh, setMaxkWh] = useState(0)
    const [selectedTab, setSelectedTab] = useState(0)
    //console.log("Installation:",installation)

    function handleDateChange(newDate){
        setStartDate(newDate)
        // we need date format: MM-DD-YYYY - with mandatort 2 digist for month & day
        const newDate_string = ("0" + newDate.getDate()).slice(-2) + "-" +("0" + (newDate.getMonth() + 1)).slice(-2) + "-" +  newDate.getFullYear()
        dispatch(setDate({newDate:newDate_string}))
    }
    function handleCalc(inst){
        dispatch(getClearSky(inst))
        dispatch(getSolarForecast(inst))
        setMaxkWh(calcDaykWh(solarData.clearSky))
        //console.log('solarData:',solarData)
    }
    function handleTabChanged(nr) {
        console.log('Clicked Tab:', nr)
        setSelectedTab(nr)
    }

    console.log('Date:',installation.date)

    return(
        <div className={styles.installationContainer}>
            <Card className={styles.installationForm}><Form /></Card>
            <Card className={styles.container_date}><Calendar date={startDate} onChange ={handleDateChange} onCalc={()=>handleCalc(installation)}/></Card>
            <Card className={styles.container_widgets}><OverviewWidgets data = {solarData}  selectedTab={selectedTab} /></Card>
            <Card className={styles.container_graph}><Tabs data = {solarData} installation = {installation} maxKwh={maxkWh} onTabChanged={handleTabChanged} ></Tabs></Card>
        </div>
    )
}
export default Installation