import { useState } from "react";
import styles from "./Tabs.module.css";
import Tab from "./Tab";
import WelcomeTab from "./WelcomeTab";
import Graph from '../../../components/InstallationPage/Graph'

import { calcDaykWh, calcMonthkWh, calcPredictedkWh, calcYearkWh,calcPredictedYearkWh } from "../../../services/solar/solarCalc";


const Tabs = (props) => {
    const [activeTab, setActiveTab] = useState(0)
    const tab = [
        {id:0, title:'Clear Sky', active: true},
        {id:1, title:'Forecast 24h', active: false},
        {id:2, title:'Forecast 48h', active: false},
        {id:3, title:'Forecast 7 days', active: false},
        {id:4, title:'Forecast Year', active: false},
    ]

    const passiveStyle=styles.tab_name
    const activeStyle=styles.tab_name + " " + styles.active_tab

    const graphConfig = {
        //clear Sky graph
        clearsky:[{type:"clearsky",title:'Clear Sky Power (Watt) - max day = ' + calcDaykWh(props.data.clearSky.slice(0,96)) + ' kWh',y:'clear_sky',stroke : '#4465B7',strokeWidth:3}],
        // Forecast 24h
        forecast24h:[{type:"24h",title:'Clear Sky Power (Watt) - max 24h = ' + calcDaykWh(props.data.solarForecast.slice(0,96)) + ' kWh',y:'clear_sky',stroke : '#4465B7',strokeWidth:3},
        {title:'Predicted Power (Watt) - max 24h = ' + calcPredictedkWh(props.data.solarForecast.slice(0,96)) + ' kWh',y:'P_predicted',stroke : '#be9557',strokeWidth:2}],
        // Forecast 48h
        forecast48h:[{type:"48h",title:'Clear Sky Power (Watt) - max 48h = ' + calcDaykWh(props.data.solarForecast.slice(0,192)) + ' kWh',y:'clear_sky',stroke : '#4465B7',strokeWidth:3},
        {title:'Predicted Power (Watt) - max 48h = ' + calcPredictedkWh(props.data.solarForecast.slice(0,192)) + ' kWh',y:'P_predicted',stroke : '#be9557',strokeWidth:2}],
        // Forecast 7d
        forecast7d:[{type:"7d",title:'Clear Sky Power (Watt) - max 7d = ' + calcDaykWh(props.data.solarForecast) + ' kWh',y:'clear_sky',stroke : '#4465B7',strokeWidth:3},
        {title:'Predicted Power (Watt) - max 7d = ' + calcPredictedkWh(props.data.solarForecast) + ' kWh',y:'P_predicted',stroke : '#be9557',strokeWidth:2}],
        // Forecast Year
        forecastyear:[{type:"forecastyear",title:'Year Total  : ' + calcYearkWh(calcMonthkWh(props.installation, props.data.clearSky)) + ' kWh',y:'P',stroke : '#be9557',strokeWidth:3},
        {title:'Year Total Predicted  : ' + calcPredictedYearkWh(calcMonthkWh(props.installation, props.data.clearSky)) + ' kWh',y:'P',stroke : '#e4b46c',strokeWidth:3}]
    }

    function onClickHandler(e){
        const { id} = e.target;
        setActiveTab(parseInt(id))
        props.onTabChanged(parseInt(id))
    }

    function reformatHoursData(data, aantalDagen) {
        //transform to 'HH:MM' for x-as label and start from 05:00
        // data is not writeable => we take deepcopy
        if (data[0]!==undefined) {
            let formattedData = structuredClone(data);
        
            let startAtIndexToDisplay = 0
            let stopAtIndexToDisplay
            // Get index where hours >= 5
            for (let item in data){
                // js counts in ms - not secs
                let date = new Date(data[item]['dt']*1000)
                
                if (date.getHours() >= 5){break}
                startAtIndexToDisplay +=1
            }
            // Set stopIndex to remove data depending on data window
            switch(aantalDagen) {
                case '1d':
                  // 22h30 = 22,5h x 4 = 90
                  stopAtIndexToDisplay = 90
                  break;
                case '2d':
                  // 2e dag stop om 22h30 = 24x4 + 22,5h x 4 = 186
                  stopAtIndexToDisplay = 186
                  break;
                case '7d':
                    // 7 dagen = 7 x 24x4 = 672
                  stopAtIndexToDisplay = 672
                  break;
                case '365d':
                    // 7 dagen = 7 x 24x4 = 672
                  stopAtIndexToDisplay = 12
                  break;
                default:
                    stopAtIndexToDisplay = 672
              }
        
            for (let item in data) {
                //Convert epcoch sec to HH:MM format for graph and js counts in millisecs
                let d = new Date(data[item]['dt'] * 1000).toLocaleString('nl-BE',{timeZone:'Europe/Brussels'});
                const [, timeValues] = d.split(" ");
                const [hours, minutes,] = timeValues.split(":");
                formattedData[item]["date"] = `${hours}:${minutes}`;
            }
            return formattedData.slice(startAtIndexToDisplay,stopAtIndexToDisplay);
        }
      };

      let noData = props.data.clearSky.length === 0
    
  return (
  <div className={styles.container}>
    <div className={styles.tab_headings}>
                <div id={0} className={tab[0].id===activeTab?activeStyle:passiveStyle} onClick={onClickHandler}>{tab[0].title}</div>
                <div id={1} className={tab[1].id===activeTab?activeStyle:passiveStyle} onClick={onClickHandler}>{tab[1].title}</div>
                <div id={2} className={tab[2].id===activeTab?activeStyle:passiveStyle} onClick={onClickHandler}>{tab[2].title}</div>
                <div id={3} className={tab[3].id===activeTab?activeStyle:passiveStyle} onClick={onClickHandler}>{tab[3].title}</div>
                <div id={4} className={tab[4].id===activeTab?activeStyle:passiveStyle} onClick={onClickHandler}>{tab[4].title}</div>
    </div>
    <div className={styles.tab_content}>
        {noData&&<WelcomeTab/>}
        {((tab[0].id===activeTab)&&(!noData))&&<Tab>
            
            <Graph data={reformatHoursData(props.data.clearSky,'1d')} 
                installation ={props.installation}
                x_key='date'
                config = {graphConfig.clearsky}
                id = 'clearsky'
                type = 'lineChart'
            />
            </Tab>}
        {((tab[1].id===activeTab)&&(!noData))&&<Tab>
            <Graph data={reformatHoursData(props.data.solarForecast,'1d')} 
                installation ={props.installation}
                x_key='date'
                config = {graphConfig.forecast24h}
                id = '24h'
                type = 'lineChart'
            />
            </Tab>}
        {((tab[2].id===activeTab)&&(!noData))&&<Tab>
            <Graph data={reformatHoursData(props.data.solarForecast,'2d')} 
                installation ={props.installation}
                x_key='date'
                config = {graphConfig.forecast48h}
                id = '48h'
                type = 'lineChart'
            /></Tab>}
        {((tab[3].id===activeTab)&&(!noData))&&<Tab>
            <Graph data={reformatHoursData(props.data.solarForecast,'7d')} 
                installation ={props.installation}
                x_key='date'
                config = {graphConfig.forecast7d}
                id = '7d'
                type = 'lineChart'
            /></Tab>}
        {((tab[4].id===activeTab)&&(!noData))&&<Tab>
            <Graph data={calcMonthkWh(props.installation, props.data.clearSky,'365d')} 
                installation ={props.installation}
                x_key='month'
                config = {graphConfig.forecastyear}
                id = 'forecastyear'
                type = 'barChart'
            /></Tab>}
    </div>
  </div>
  );
};
export default Tabs;
