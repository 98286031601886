import React from "react";

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";


const Graph = (props) => {

  // let data

  // if (props.id==="clearsky"){ 
  //   data = props.data.slice(0,72)
  // } else if ((props.id==="24h")){
  //   data = props.data.slice(0,72)
  // } else if(props.id==="48h"){
  //   data = props.data.slice(0,166)
  // } else if(props.id==="7d"){
  //   data = props.data.slice(0,672)
  // }
  
  console.log("Graph data:", props.data);

  const lines = props.config.map((item,index) => (
    <Line
      name={item.title}
      key={index}
      type="monotone"
      dataKey={item.y}
      dot={false}
      stroke={item.stroke}
      strokeWidth= {item.strokeWidth}
    />
  ));
  
  if (props.type==='lineChart'){
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={props.x_key}
          //type="number"
          //allowDataOverflow={true}
          //interval={7}
          angle={-45}
          dx={-13}
          dy={13}
          tick={{ fontSize: 12 }}
          //type='string'
          //domain={['auto', 'auto']} 
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={46} wrapperStyle={{fontSize: "0.8rem"}} />
        <ReferenceLine
          y={props.installation.wattInvertor}
          label=""
          stroke="red"
          strokeDasharray="3 3"
        />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  )
  } else if (props.type==='barChart') {
    return(
      <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={props.x_key}
          
          angle={-45}
          dx={-13}
          dy={13}
          tick={{ fontSize: 12 }}
          //type='string'
          //domain={['auto', 'auto']} 
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={36} wrapperStyle={{fontSize: "0.8rem"}} />
        <Bar name={props.config[0].title} dataKey="P" fill={props.config[0].stroke}/>
        <Bar name={props.config[1].title} dataKey="P_predicted" fill={props.config[1].stroke}/>
      </BarChart>
    </ResponsiveContainer>
    )
  }
};
export default Graph;

//<Line name={`Clear Sky Power (Watt) - max day = ${props.maxKwh} kWh`} type="monotone" dataKey={props.y_key} dot={false} stroke="#e4b46c" />
