import React from 'react'
import styles from './MainHeader.module.css'
import Navbar from './Navbar'

const MainHeader = (props)=>{
    return (
        <header className={styles.header}>
            <Navbar user={props.user}/>
        </header>
    )
}
export default MainHeader