import styles from './WeatherWidget.module.css'

const WeatherWidget = (props) => {
    let source
     if (props.icon) {
        source = require(`./icons/${props.icon}`);
     }
    else  {source=require('./icons/03d.png')}

    return (
        <div className={styles.container}>
            {(props.type==='icon')&&<img  src= {source} alt="weather icon" className={styles.img}/>}
            {(props.type==='value')&&<p className={styles.value}>{props.value} {props.dimension}</p>}
            <p className={styles.name}>{props.name}</p>
        </div>
    )
}
export default WeatherWidget