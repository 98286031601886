import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import {store} from './store/store'
import {Provider} from 'react-redux'

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Provider store={store}>
     <BrowserRouter>
        <App />
     </BrowserRouter>
    </Provider>
  
);


