import React from "react";
import styles from "./GeoMap.module.css";
import Coordinates from "./Coordinates/Coordinates";
//import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {useLoadScript} from "@react-google-maps/api"
import Map from "./Map/Map";

const GeoMap = (props) => {

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyDt6ug8abnKS98cC0v4Yg4-zfNB9o88nOE",
        libraries: ["places"],
    })
    
  return (
    <div className={styles.container}>
      <Coordinates home={props.home}/>
      { (!isLoaded)? <div>Loading...</div>:<Map home={props.home} handleClick={props.handleClick}/>}
    </div>
  );
};

export default GeoMap;
