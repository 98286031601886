export const weather = {
    211:{description:'Thunderstorm', icon:"11d.png"},
    500:{description:'Light rain', icon:"10d.png"},
    501:{description:'Moderate rain', icon:"10d.png"},
    502:{description:'Heavy rain', icon:"10d.png"},
    600:{description:'Light snow', icon:"13d.png"},
    601:{description:'Snow', icon:"13d.png"},
    701:{description:'Fog', icon:"50d.png"},
    800:{description:'Clear sky', icon:"01d.png"},
    801:{description:'Few clouds', icon:"02d.png"},
    802:{description:' 50% clouds', icon:"03d.png"},
    803:{description:'Cloudy', icon:"04d.png"},
    804:{description:'Clouds', icon:"04d.png"}
}

export const getWeatherType = (id) => {
    
    return weather[id]
}
