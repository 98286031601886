import styles from './RatingWidget.module.css'
import Rating from '../../UI/Rating/Rating'

const RatingWidget = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.starcontainer}>
                <Rating value={props.value} max={props.max}/>
            </div>
            <p className={styles.name}>{props.name}</p>

        </div>
    )
}
export default RatingWidget