import React from "react";
import styles from "./LineSeparator.module.css";

const LineSeparator = (props) => {
  const lineStyle = {
    color: props.color,
    borderColor: props.color,
  };
  return (
    <div className={styles.lineseparator} style={lineStyle}>
      <h6 className={styles.lineseparator} style={lineStyle}>
        {props.text}
      </h6>
    </div>
  );
};
export default LineSeparator;
