import React, { Fragment, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import styles from "./Navbar.module.css";
import { NavLink } from "react-router-dom";
import Button from "./UI/Button";

import { useDispatch } from "react-redux";
import { logOut } from "../features/user/userSlice";

import logo from "../static/img/SolarLogo-25x25.png";

const Navbar = (props) => {
  const [burgerClicked, setBurgerClicked] = useState(false);
  const dispatch = useDispatch();
  const toggleBurger = () => setBurgerClicked((prev) => !prev);
  let links;

  if (props.user.userRoles.includes("authenticated")) {
    links = (
      <Fragment>
        <NavLink onClick={()=>setBurgerClicked(false)} to="/Installation">Installation</NavLink>
        <NavLink onClick={()=>setBurgerClicked(false)} to="/User">Account</NavLink>
        <a href="/.auth/logout">
          <Button
            onClick={() => dispatch(logOut())}
            btnSize="btn-small"
            btnStyle="btn-primary-solid"
          >
            Sign Out
          </Button>
        </a>
      </Fragment>
    );
  } else {
    links = (<div></div>)
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
          <img src={logo} alt="logo" />
          <div className={styles.logo_txt}>Solar Forecast</div>
      </div>
      <nav className={burgerClicked?[styles.navbar,styles.active].join(' '):styles.navbar}>
        <div className={styles.nav_links}>{links}</div>
      </nav>
      <div className={styles.mobile_btn} onClick={toggleBurger}>
        {burgerClicked && <AiOutlineClose size={36} style />}
        {!burgerClicked && <AiOutlineMenu size={36} />}
      </div>
    </div>
  );
};

export default Navbar;
