
import styles from './WelcomeTab.module.css'

const WelcomeTab = () => {
    return(
        <div className={styles.welcome}>
            <p>1. Enter your solar details on the left side.</p>
            <p>2. Optional: choose a date.</p>
            <p>3. Click "Calculate".</p>
            <br/>
            <br/>
            <p className={styles.remark}>Remark: Solar forecast is always NOW + 48h since based upon the weather prediction of your location </p>
        </div>
    )
}
export default WelcomeTab