
import styles from "./Calendar.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../UI/Button";

const Calendar = (props) => {
 
  return (
    <div className={styles.container}>
      <div className={styles.date}>
        <h5 className={styles.date_label}>Date:</h5>
      <DatePicker
        dateFormat="dd-MM-yyyy"
        selected={props.date}
        onChange={props.onChange}
        
      />
      </div>
      <Button
        btnSize="btn-wide"
        btnStyle="btn-primary-solid"
        handleClick={props.onCalc}
      >
        Calculate
      </Button>
    </div>
  );
};
export default Calendar;
