import React from 'react'
import styles from './Form.module.css'
import Entry from './Entry'
import GeoMap from '../UI/GeoMap/GeoMap'

import { useSelector, useDispatch } from "react-redux";
import { setAzimuth, setTotalWattPeak, setWattInvertor, setTilt, setLocation } from '../../features/installation/installationSlice';

const tooltip = {
    Watt_peak: "This is the total Watt peak of your installation.",
    Invertor: ' This is the invertor Power in Watt',
    Azimuth: 'Orientation of your panels: eg south = 180',
    Tilt: 'This is the inclination of your panels (or roof), typically 35 deg'
}

const Form = ()=>{
    const dispatch = useDispatch()
    const installation = useSelector((store) => store.installation)

    function handleTotalWattPeakChange(e){
        const newTotalWattPeak = e.target.value
        dispatch(setTotalWattPeak({newTotalWattPeak}))
    }
    function handleWattInvertorChange(e){
        const newWattInvertor = e.target.value
        dispatch(setWattInvertor({newWattInvertor}))
    }
    function handleAzimuthChange(e){
        const newAzimuth = e.target.value
        dispatch(setAzimuth({newAzimuth}))
    }
    function handleTiltChange(e){
        const newTilt = e.target.value
        dispatch(setTilt({newTilt}))
    }
    function newHome(newLocation){
        dispatch(setLocation({newLocation}))
    }

    return (
        <form className={styles.container}>
            <h2>Solar Installation</h2>
            <Entry title='Total Watt Peak' name='totalWattPeak' value={installation.totalWattPeak} dim='Watt peak' info={tooltip.Watt_peak} onChange={handleTotalWattPeakChange}/>
            <Entry title='Watt Invertor' name='wattInvertor' value={installation.wattInvertor} dim='Watt' info={tooltip.Invertor} onChange={handleWattInvertorChange}/>
            <Entry title='Azimuth' name='azimuth' value={installation.azimuth} dim='deg' info={tooltip.Azimuth} onChange={handleAzimuthChange}/>
            <Entry title='Tilt' name='tilt' value={installation.tilt} dim='deg' info={tooltip.Tilt} onChange={handleTiltChange}/>
            <GeoMap home={installation.location} handleClick={newHome}/>
        </form>
    )
}
export default Form