// Custom Button component - global css defined in App.css
// props (will be destructured): type-btnSize-btnStyle + default when no prop defined
// events: onClick
import "../../App.css";

const STYLES = [
  "btn-primary-solid",
  "btn-secundary-solid",
  "btn-success-solid",
  "btn-danger-solid",
  "btn-warning-solid",
  "btn-info-solid",
  "btn-light-solid",
  "btn-dark-solid",
  "btn-primary-outline",
  "btn-secundary-outline",
  "btn-success-outline",
  "btn-danger-outline",
  "btn-warning-outline",
  "btn-info-outline",
  "btn-light-outline",
  "btn-dark-outline",
];
const SIZES = ["btn-medium", "btn-small","btn-wide"];

const Button = ({ children, type, handleClick, btnSize, btnStyle }) => {
  // prettier-ignore
  const checkBtnType = ["button", "reset", "submit"].includes(type)? type: "button";
  const checkBtnSize = SIZES.includes(btnSize) ? btnSize : SIZES[0];
  const checkBtnStyles = STYLES.includes(btnStyle) ? btnStyle : STYLES[0];

  return (
    <button
      className={`btn ${checkBtnSize} ${checkBtnStyles}`}
      type={checkBtnType}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
export default Button;
