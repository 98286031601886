import React from 'react'
import styles from './Welcome.module.css'
import SignInPage from '../components/SignInPage/SignInPage'

const Welcome = (props)=>{
    return(
        <div className={styles.container}>
            {!props.user.userId && <SignInPage handleClick={props.handleClick} />}
            {props.user.userId && 
                <div className={styles.welcomeContainer}>
                    <div className={styles.motto}>Predict Your Solar Energy</div>
                    <p className={styles.welcomeMessage}>Get minute based solar predictions for your solar panels to maximize your own energy consumption and collaborate in an open platform.</p>

                </div>}
        </div>

    )
}
export default Welcome