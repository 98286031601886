import React from "react";
import styles from "./Login.module.css";

const Login = (props) => {
  const provStyle = {
    borderColor: props.provider.border,
  };
  const txtStyle = {
    color: props.provider.color,
    backgroundColor: props.provider.background,
  };

  return (
    <a
      href={`/.auth/login/${props.provider.route.toLowerCase()}`}
    >
      <div className={styles.login} style={provStyle}>
        <div className={styles.boxLogo}>
          <img
            src={require(`../../static/img/Login/${props.provider.logo}`)}
            alt="logo"
          />
        </div>
        <div className={styles.boxTxt} style={txtStyle}>
          <h5>Sign In With {props.provider.name}</h5>
        </div>
      </div>
    </a>
  );
};
export default Login;
