import { Monthly_kWh } from './monthly_kWh'
import { Daily_kWh } from './daily_kWh'

export const calcDaykWh = (data) => {
    let totalKwh =0

    for (let item in data){
        totalKwh = totalKwh + (data[item].clear_sky/4/1000)
    }
    return totalKwh.toFixed(1)
}
export const calcPredictedkWh = (data) => {
    let totalKwh =0

    for (let item in data){
        totalKwh = totalKwh + (data[item].P_predicted/4/1000)
    }
    return totalKwh.toFixed(1)
}

export const calcMonthkWh = (installation,clearskyToday) => {
    // Goal = produce a corrected dataArray of MonthlykWh
    // correction = clearsky of given date vs ref clearsky of that date
    const dayOfYear = date => Math.floor((date-new Date(date.getFullYear(),0,0))/(1000*60*60*24))
    let monthData = []
    const [d,m,y] = installation.date.split('-')
    let dayNr = dayOfYear( new Date(y,m-1,d))
    
    const corrFactor = calcDaykWh(clearskyToday)/Daily_kWh[dayNr-1]['clear_sky']

    for (const month of Monthly_kWh) {
        let newMonth = {
            month: month.month,
            P_predicted: Math.round((month.kWh_predict * corrFactor)*10)/10,
            P: Math.round((month.kWh * corrFactor)*10)/10
        }
        monthData.push(newMonth)
    }
    return monthData
}

export const calcYearkWh = (monthData) => {
    let total = monthData.reduce((Tot,month) => Tot + month.P ,0)
    return total.toFixed()
}
export const calcPredictedYearkWh = (monthData) => {
    let total = monthData.reduce((Tot,month) => Tot + month.P_predicted,0 )
    return total.toFixed()
}