import styles from './Rating.module.css'
import {ReactComponent as IconStar} from './star.svg'

const Rating = ({value,max,className})=>{
    const percentage = Math.round((value/max)*100)

    return (
        <div className={styles.container}>
            {Array.from(Array(max).keys()).map((_,i)=> (
                <IconStar key={i} className={styles.star}/>
            ))}
        {/* Render a div overlayed on top of the stars that do not be filled*/}
        <div className={styles.overlay} style={{width:`${100-percentage}%`}}/>
        </div>
    )
}
export default Rating
