import axios from 'axios'
const baseUrl = 'api/users'

//Check user in db - if exist return user else null
export const checkUser = async (userId) => {
    console.log('executing checkUser')
    try {
        const user = await axios({
            url: baseUrl + '/?userId=' + userId ,
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            }
        })
        return user.data
    } catch {
        console.log('error in checkUser')
    }
}

//Create new user in db 
export const createUser = async (newUser) => {
    console.log('executing createUser')
    try {
        const user = await axios({
            url: baseUrl,
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data: newUser
        })
        return user.data
    } catch {
        console.log('error in createUser')
    }
}

//Update user in db 
export const updateUser = async (thisUser) => {
    console.log('executing updateUser')
    try {
        const user = await axios({
            url: baseUrl,
            method:'PUT',
            headers:{
                'Content-Type':'application/json'
            },
            data: thisUser
        })
        return user.data
    } catch {
        console.log('error in updateUser')
    }
}