import { createSlice } from "@reduxjs/toolkit";

const today = new Date()
const today_string = ("0"+today.getDate()).slice(-2)+"-"+("0"+ (today.getMonth()+1)).slice(-2)+"-"+today.getFullYear()

const initialState = {
    user: 'John Doe',
    date:today_string,
    totalWattPeak: 7400,
    wattInvertor: 5040,
    azimuth: 180,
    tilt: 44,
    altitude:70,
    location: {
        lat:51.2404,
        lng:3.2248
    },
    timezone:"Europe/Brussels"
}

export const installationSlice = createSlice({
    name: 'installation',
    initialState: initialState,
    reducers:{
        setDate: (state,{payload}) => {
            state.date = payload.newDate
        },
        setTotalWattPeak: (state,{payload}) => {
            state.totalWattPeak = payload.newTotalWattPeak
        },
        setWattInvertor: (state,{payload}) => {
            state.wattInvertor = payload.newWattInvertor
        },
        setAzimuth: (state,{payload}) => {
            state.azimuth = payload.newAzimuth
        },
        setTilt: (state,{payload}) => {
            state.tilt = payload.newTilt
        },
        setAltitude: (state,{payload}) => {
            state.altitude = payload.newAltitude
        },
        setLocation: (state,{payload}) => {
            //format location = {lat: , lng: }
            state.location = payload.newLocation
        },
    }
})

export const {setDate,setTotalWattPeak,setWattInvertor,setAzimuth,setTilt,setAltitude,setLocation} = installationSlice.actions
export default installationSlice.reducer