import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    //Oauth received keys
    userId: "",
    userDetails:"", //username or email
    identityProvider: "",
    userRoles:[],
    //Cosmosdb-mongo key
    _id: "",
    //Solar-forecast
    token: "",
    createdAt:"",
    updatedAt:"",
    log:[],
    installation: {}
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers:{
        setClientPrincipal: (state,{payload}) => {
            if (payload) {
                state = {...payload}
                return state
            } else {state = initialState}
            console.log('Reducer state:', state)
        },
        logOut: (state) => () => {
            console.log('user was reset to null')
            state = initialState
        }

    }
})

export const {setClientPrincipal,logOut} = userSlice.actions
export default userSlice.reducer
