import styles from "./OverviewWidgets.module.css";
import WeatherWidget from "./WeatherWidget";
import RatingWidget from "./RatingWidget"
import { calcPredictedkWh, calcDaykWh } from "../../../services/solar/solarCalc";
import { getWeatherType } from "../../../services/weather/weather";

const OverviewWidgets = (props) => {

  let temp,score,weather

  function calcSolarScore(tabNr){
    // We calc a score 0..5 (float 1 digit)
    // As a percentage of predicted vs cleasky - over the window period (1d,2d,7d)
    let data
    switch (tabNr) {
      case 0:
        // no forecast for pure clearsky on tab 0
        score = 0
        break;
      case 1:
        // 1d = 24 x 4 (15min) = 96
        data = props.data.solarForecast.slice(0,96)
        break
      case 2:
        // 2d = 2 X 24 x 4 = 192
        data = props.data.solarForecast.slice(0,192)
        break
      default:
        data = props.data.solarForecast
    }
    // calcPredicted takes P_predicted key and calcDayKWh take clearsky key
    const forecastkWh = calcPredictedkWh(data)
    const clearSkyykWh = calcDaykWh(data)
    score = 5 * (forecastkWh / clearSkyykWh)
    return score.toFixed(1) 
    }  


  if (props.data.solarForecast.length > 0 ) {
    temp = Math.round(props.data.solarForecast[0].temp - 273);
    score = calcSolarScore(props.selectedTab)
    weather = getWeatherType(props.data.solarForecast[0].weather_id);
  } else {
    // default
    score = 0
    temp = 15
    weather = {description: "Few clouds",icon:"02d.png"}
  }

  return (
    <div className={styles.container}>
      <h6 className={styles.title}> Weather</h6>
      <div className={styles.widgets}>
        <WeatherWidget name={weather.description} type="icon" icon={weather.icon} dimension="" />
        <WeatherWidget name="Temp" type="value" value={temp} dimension="°C" />
        <RatingWidget name="Solar Score" value = {score} max={5} />
      </div>
    </div>
  );
};
export default OverviewWidgets;
